import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/were-not-scavengers/were-not-scavengers-main.jpg" imageAlt="We're Not Scavengers" mdxType="TextMediaSplit">
      <h1>{`We're Not Scavengers`}</h1>
      <p>{`Spotted Hyenas are an apex predator found across Africa however many people view them only as scavengers, to be avoided at all costs. In the Western world this is largely due to mainstream media, such as Walt Disney’s ‘The Lion King’ and even wildlife documentaries, showing the Spotted Hyena to be evil subservients to Lions. This project focuses on changing that perception by educating the public on the truth about the Spotted Hyena, in the hopes of maintaining their population for further generations.`}</p>
      <p>{`This proposed exhibition, housed at The Natural History Museum, invites the viewer to explore the true nature of the Spotted Hyena. `}</p>
      <p>{`Using a visual system of highly detailed and accurate illustrations I have created a visual system which includes promotional materials, exhibition copy, guidebooks and merchandise. `}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/were-not-scavengers/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      